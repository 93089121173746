<script setup>
// import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,

} from "swiper/modules";
import "swiper/css";

import "swiper/css/pagination";
import 'swiper/css/navigation';
import productBox1 from "~~/components/product-box/product-box1.vue"

const localePath = useLocalePath();
const modules = ref([Navigation, Pagination]);
const router = useRouter();
const props = defineProps(['products', 'att_key', 'attribute'])
const gotoLink = () => {

  router.push(
    {
      path:  localePath('/products/search'),
      query: {
        data: JSON.stringify({ "attributeFilter": props.attribute })
      }
    })
}


</script>

<template>
  <div class=" ">
    <div class="container promo-list ">
      <!-- d-flex justify-content-between align-items-center -->
      <h3 class="mb-4">
        <NuxtLinkLocale class="promo-link" @click="gotoLink">
          {{ $t(att_key) }}
        </NuxtLinkLocale>
      </h3>
      <h6> <NuxtLinkLocale class="nav-link promo-link promo-link-right" @click="gotoLink">
          {{ $t('see_all') }}
        </NuxtLinkLocale>
      </h6>
    </div>
    <div class="container welcome-pro ">
      <div class="" :v-if="products && products.length">
        <Swiper :breakpoints="{
          320: { slidesPerView: 1, spaceBetween: 20 },
          480: {
            slidesPerView: 2, spaceBetween: 20
          },
          757: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 12
        },
          992: {
            slidesPerView: 5,
            spaceBetween: 18
          }
        }" :slidesPerView="1" :navigation="true" :grabCursor="true" :freeMode="true" :centeredSlides="false"
          :modules="modules" style="">
          <swiper-slide v-for="( item, i ) in  products" :key="i">
            <div class="product-box">
              <productBox1 :item="item" :index="i"> </productBox1>
            </div>
          </swiper-slide>
        </Swiper>
        <div v-if="false" v-for="( item, i ) in  products" :key="i" class="col-auto my-2 mx-0">
          <div class="product-box col-sm-4">
            <productBox1 :item="item" :index="i"> </productBox1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.product-box {
  border: 1px solid #b7b8b9;
  width: 100%;
  /* height: 390px; */
  border-radius: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
  transition: transform 0.3s;
}

.product-box:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  /* border: 1px solid var(--theme-deafult); */
}
</style>
