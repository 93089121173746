<script setup>
import { useUtilityStore } from "../store/UtililityStore";
import { useProductStore } from "../store/ProductStore";
import WelcomeProducts from "./WelcomeProducts.vue";
import { useUserStore } from "~/store/UserStore";
const productStore = useProductStore();
const utilityStore = useUtilityStore();
const userStore = useUserStore();

const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const products = computed(() => productStore.welcomProducts);
const isAuthenticated = computed(() => userStore.isAuthenticated);

const keys = ref([
  {
    key: 'promos', attribute: {
      price_promo: {
        "price promo": true,
      },
      "in_stock": {
        "in stock": true
      }
    },
  },
  // { key: 'best_sale', attribute: {} },
  {
    key: 'last_unit', attribute: {
      "stock_range": {
        "lte": 29,
        "gte": 1
      }
    }
  },
  // { key: 'new_arrivals', attribute: {} },
  // {
  //   key: 'incoming_release', attribute: {
  //     "status preorder": true,
  //     // Arriving soon : Product status = Preorder and shipments are underway
  //     // TODO We don't have this data in ES yet so we need to sync it
  //   }
  // },
  //  {
  //     key: 'new_announce', attribute: {
  //       "status preorder": true
  //     }
  //         // created_date in 2 weeks
  //   }, 
  // {
  //   auth: true,
  //   key: 'replenished', attribute: {
  //     "stock": {
  //       "replenished": true
  //     }
  //   }
  // }
]);
const getProducts = async () => {
  const promises = []

  for (let i = 0; i < keys.value.length; i++) {
    promises.push(productStore.fetchWelcomeProductsWithFilters({
      selectedLanguage: selectedLanguage.value,
      attribute: keys.value[i].attribute,
      key: keys.value[i].key,
      range: 32
    }));
  }
  await Promise.all(promises)
  productStore.setWelcomeProductsToRedis()

};

onBeforeMount(() => {
  productStore.refillWelcomeProductsFromRedis()
})
onMounted(() => {
  setTimeout(() => {
    getProducts();
  }, 100);
});

</script>

<template>
  <template v-if="products">

  </template>
  <div class="container " v-for="(k, index) in keys" :key="index">
    <!-- v-if="!k?.auth || (k?.auth === true && isAuthenticated)" -->
    <!-- d-flex justify-content-center align-items-center -->
    <WelcomeProducts v-if="products[k.key] && products[k.key]?.length" class="row" :products="products[k.key]"
      :attribute="k.attribute" :key="k.key" :att_key="k.key">
    </welcomeProducts>
  </div>


</template>

<style scoped></style>
