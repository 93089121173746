<template>
  <Lazywidgets-newsletter-popup></Lazywidgets-newsletter-popup>
  <div class="slides top-cover-slide" v-if="coverSlider.length">
    <NuxtLinkLocale :to="cover.url" v-if="false && cover.image">
      <img :src="imgUrl(cover.image.url)" alt="Image" id="slide-img" />
    </NuxtLinkLocale>

    <swiper :rewind="true" :watchSlidesProgress="true" :spaceBetween="0" :slidesPerView="1" :navigation="true"
      :pagination="true" :grabCursor="true" :centeredSlides="false" :history="{
        key: 'slide-cover-main-top',
      }" :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }" :modules="modules" class="mySwiper swiper">

      <swiper-slide v-for="slide in coverSlider" :key="slide.id" class="swiper-slide">
        <NuxtLinkLocale v-if="slide.url" :to="slide.url" :key="slide.url">
          <img class="cover-slider-img" :src="imgUrl(slide.image.url, 900)" alt="Image" id="slide-img" />
        </NuxtLinkLocale>
        <div v-else>AHHHHH{{ slide }}</div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="container">
    <h2 class="text-center mid-text">{{ $t('this_weeks_highlights') }}</h2>
  </div>
  <div class="slides" v-if="sliderImages.length">
    <swiper :rewind="true" :watchSlidesProgress="true" :spaceBetween="0" :slidesPerView="slidesPerView"
      :navigation="true" :pagination="true" :grabCursor="true" :centeredSlides="false" :history="{
        key: 'cover-slide-bottom',
      }" :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }" :modules="modules" class="mySwiper swiper">
      <swiper-slide v-for="slide in sliderImages" :key="slide.id + '_sws'" class="swiper-slide">
        <template v-if="slide?.url">
          <NuxtLinkLocale :to="slide.url" :key="slide.id + '_nl'">
            <img :key="slide.id + '_img'" class="cover-slider-bottom-img" :src="imgUrl(slide.image.url, 500)"
              alt="Image" id="slide-img" />
          </NuxtLinkLocale>
        </template>
      </swiper-slide>
    </swiper>
    <!-- <promo-products></promo-products> -->
    <WelcomeProductsList></WelcomeProductsList>
  </div>
</template>

<script setup>
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, History, Autoplay } from "swiper/modules";
import { useCmsStore } from "~/store/cms/cmsStore";
import WelcomeProductsList from "~/components/WelcomeProductsList.vue";

const modules = [Navigation, Pagination, Autoplay];
const cmsStore = useCmsStore();
const sliderImages = computed(() => cmsStore.sliderImages);
const cover = computed(() => cmsStore.cover);
const coverSlider = computed(() => cmsStore.coverSlider);
const slidesPerView = ref(3);
const imgUrl = (url, w, q = 90) => cmsStore.getImageUrl(`/cdn-cgi/image/width=${w},quality=100,fit=pad,background=%23FFFFFF${url}`);
</script>

<style scoped>
#slide-img {
  width: -webkit-fill-available;
}

.slides {
  padding-bottom: 10px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: block;
  margin: 0 2px;
  /*justify-content: center;
  align-items: center;*/
}

.swiper {
  width: 100%;
  height: fit-content;
  box-sizing: content-box;
}

.category-text {
  margin: 0 0 0 0 !important;
}
</style>
